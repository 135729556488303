import React from 'react';
import classnames from 'classnames';
// types
import { ILabelProps } from './types';
// styles
import styles from './styles.module.scss';
import { isTextWrapped } from '@/utils/helpers';

const MIN_CHAR_LENGTH_FOR_P5_TEXT = 45;

const Label = (props: ILabelProps) => {
  const {
    value,
    styleClass,
    className,
    onClick,
    readOnly,
    hasTooltip = false,
    clickable = false,
    wrapperClass = '',
    hideTooltip = false,
    isMandatory = false,
    isSponsorLandingPage = false,
  } = props;

  const pointerText =
    !!onClick || !!clickable ? styles.clickHover : styles.normalText;
  const [
    isTextWrappingOrMoreThanThresholdChars,
    setIsTextWrappingOrMoreThanThresholdChars,
  ] = React.useState(false);
  const labelRef = React.useRef<HTMLElement | null>(null);

  React.useEffect(() => {
    if (labelRef.current) {
      const value =
        isTextWrapped(labelRef.current) ||
        labelRef.current?.innerText?.length > MIN_CHAR_LENGTH_FOR_P5_TEXT;
      setIsTextWrappingOrMoreThanThresholdChars(value);
    }
  }, [labelRef.current]);

  return (
    <div
      className={classnames(
        hasTooltip ? styles.labelWrapperAbs : styles.labelWrapper,
        {
          [styles[wrapperClass]]: wrapperClass && wrapperClass,
        },
      )}
    >
      {/** not using tooltip for now in label's: issue: ENG-6368 */}
      {/* {value && value.length > 40 && !hideTooltip && (
        <div className={styles.tooltip}>{value}</div>
      )} */}
      <span
        className={
          isMandatory && isSponsorLandingPage ? styles.isMandatory : ''
        }
      >
        <label
          ref={labelRef}
          onClick={onClick}
          className={classnames(
            readOnly ? styles.disabledText : pointerText,
            styleClass ? styles[styleClass] : '',
            isMandatory && !isSponsorLandingPage ? styles.isMandatory : '',
            className,
            {
              [styles['P5Text']]: isTextWrappingOrMoreThanThresholdChars,
            },
          )}
        >
          {value}
        </label>
      </span>
    </div>
  );
};

export default Label;
